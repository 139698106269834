import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ContentWrapper from "styles/contentWrapper"

const spot1 = 'https://hrflow-ai.imgix.net/backgrounds/spot.svg'
const spot2 = 'https://hrflow-ai.imgix.net/backgrounds/mini-spot.svg'
const spot3 = 'https://hrflow-ai.imgix.net/backgrounds/circle-spot.svg'

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 3rem 0;
  background: linear-gradient(
  357.83deg
  ,rgba(0,207,225,0) 1.57%,rgba(0,207,225,.06) 40.64%,rgba(255,255,255,.02) 99.79%);
      mix-blend-mode: normal;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    background-image: url(${spot1}), url(${spot2}), url(${spot3});
    background-size: 16rem 22.5rem, 1.5rem 2rem, 2.55rem 2.55rem;
    background-position: 5% 45%, 5% 27%, 98% 98%;
    background-repeat: no-repeat;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }
`

const StyledFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 50rem;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 3rem;
  div {
    margin: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    div {
      max-width: 45%;
    }
  }
`

const StyledLayers = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-gap: 2rem;
  grid-auto-rows: minmax(250px, auto);
  justify-items: center;
  .item {
    max-width: 300px;
    .item-content {
      justify-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .logo-wrapper {
      background: #EDFAF9;
      border-radius: 50%;
      padding: 1rem;
    }
    .logo {
      width: 50px;
      height: auto;
    }
    h3 {
      margin: 0;
      margin: 0.75rem 0;
      text-align: center;
    }
    .highlghited {
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0.75rem;
    }
    .text {
      text-align: center;
      color: #00495d;
    }
    a {
      icon {
        margin-left: 0.3rem;
        vertical-align: middle;    
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      &:nth-of-type(1), &:nth-of-type(4) {
        justify-self: start;
      }
      &:nth-of-type(2), &:nth-of-type(5) {
        justify-self: center;      
      }
      &:nth-of-type(3), &:nth-of-type(6) {
        justify-self: end;      
      }
    }
    transition: all .3s ease;
    &:hover {
      transform: scale(1.05) translate(0, -10px)!important;
      transition: transform .3s;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-rows: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    grid-template-columns: repeat(1, 1fr);
    .item {
      max-width: 90%;
    }
    grid-gap: 4rem;
  }
`

const Layers = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { items } = exports

  // Only trigger animations if the intro is done or disabled
  const iControls = useAnimation()
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    const sequence = async () => {
      if (inView) {
        await iControls.start(i => ({
          opacity: 1,
          scaleY: 1,
          transition: { delay: i * 0.1 },
        }))
      }
    }
    sequence()
  }, [inView, iControls])

  return (
    <StyledSection id="layers">
      <StyledContentWrapper>
        <div className="section-label">{frontmatter.label}</div>
        <h2 className="section-title">{frontmatter.title1}<br />{frontmatter.title2}</h2>
{/*         <StyledFlex>
          <div className="text">{frontmatter.textLeft}</div>
          <div className="text">{frontmatter.textRight}</div>
        </StyledFlex> */}
        <StyledLayers ref={ref}>
          {items.map(({ title, logo, subtitle, text, link }, key) => {
            const image = getImage(logo)
            return (
              <motion.div
                className="item"
                key={key}
                custom={key}
                initial={{ opacity: 0, scaleY: 0 }}
                animate={iControls}
              >
                <Link to={`${link}`} className="item-content">
                  <div className="logo-wrapper">
                    <GatsbyImage className="logo" alt={title} image={image} />
                  </div>
                  <h3>{title}</h3>
                  <div className="text mb-075">{text}</div>
                </Link>
              </motion.div>
            )
          })}
        </StyledLayers>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Layers.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          items: PropTypes.array.isRequired,
          shownItems: PropTypes.number.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Layers
